import React from 'react'
import classNames from 'classnames'

import {
  BioMarktIcon,
  PreparationTimeIcon,
  PortionsIcon,
  CaloriesIcon,
  NutrionalFeatureListItem,
  BlockContent,
  BioIngredientsList,
  BioRecipeInformationItem
} from '../shared'

const RecipePrintContent = ({ data, portionCounter, portionAmount }) => {
  let hasInformation = false
  if (data.preparationTime || data.caloricValue || data.portionAmount) {
    hasInformation = true
  }
  return (
    <div className="hidden print:flex flex-col w-singlePageContentSection mx-auto recipe-print">
      <span className="w-32 h-32 absolute">
        <BioMarktIcon />
      </span>
      <h1 className="text-center break-words mt-40">{data.title}</h1>
      <ul className="flex w-2/5 mx-auto list-none mt-8 justify-center">
        <NutrionalFeatureListItem
          widthAndMarginClassnames="w-12 h-12 ml-3 first:ml-0"
          feature={data.vegetarian}
          text="Vegetarisch"
        />
        <NutrionalFeatureListItem
          widthAndMarginClassnames="w-12 h-12 ml-3 first:ml-0"
          feature={data.vegan}
          text="Vegan"
        />
        <NutrionalFeatureListItem
          widthAndMarginClassnames="w-12 h-12 ml-3 first:ml-0"
          feature={data.lactosefree}
          text="Laktosefrei"
        />
        <NutrionalFeatureListItem
          widthAndMarginClassnames="w-12 h-12 ml-3 first:ml-0"
          feature={data.glutenfree}
          text="Glutenfrei"
        />
      </ul>
      <div
        className={classNames(
          'flex mt-12 justify-center',
          hasInformation && 'justify-around'
        )}>
        <div className="w-2/5">
          <img src={data?.previewImage?.image?.asset?.url} />
        </div>
        <ul className="list-none flex flex-col justify-center">
          <BioRecipeInformationItem
            title="Zubereitungszeit"
            icon={<PreparationTimeIcon />}
            text={data.preparationTime}
          />
          <BioRecipeInformationItem
            title="Brennwert"
            icon={<CaloriesIcon />}
            text={data.caloricValue}
          />
          <BioRecipeInformationItem
            title="Portionen"
            icon={<PortionsIcon />}
            text={portionCounter}
          />
        </ul>
      </div>
      <div className="w-full flex justify-between mt-16 pb-12">
        <article className="w-2/3">
          <h3>Zubereitung</h3>
          <BlockContent
            data={data._rawPreparation}
            className="mt-10 recipe-preparation-styles"
          />
        </article>
        <BioIngredientsList
          ingredientsList={data.ingredientsList}
          portionCounter={portionCounter}
          portionAmount={portionAmount}
        />
      </div>
    </div>
  )
}

export class RecipeToPrint extends React.Component {
  render() {
    return (
      <>
        <RecipePrintContent
          data={this.props.data}
          portionCounter={this.props.portionCounter}
          portionAmount={this.props.portionAmount}
        />
      </>
    )
  }
}
